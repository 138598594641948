import { Heading } from "../../ui";
import "./post.css";

interface PostType {
  label: string;
  content: string;
  isPost: boolean;
}

export const Post = ({ label, content, isPost }: PostType) => (
  <div className={`content ${isPost ? "content-margin" : ""}`}>
    <Heading label={label} textColor="black" />
    <p dangerouslySetInnerHTML={{ __html: content }} />
  </div>
);
