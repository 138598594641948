import "./navigation-url.css";

interface NavigationUrlProps {
  label: string;
  path: string;
  isActive: boolean;
}

export const NavigationUrl = ({
  label,
  path,
  isActive,
}: NavigationUrlProps) => (
  <a
    className={isActive ? "navigation-url-active" : "navigation-url"}
    href={path}
  >
    {label}
  </a>
);
