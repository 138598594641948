import { PostType } from "../interfaces/post.type";

export const psychotheraphy: PostType = {
  label: "Psychoterapia",
  text: ` <b>Psychoterapia</b> jest specyficzną <b>metodą leczenia</b> i osiągania zmian w zakresie zdrowia psychicznego, samopoczucia, zachowania oraz funkcjonowania osobowości. 
          Cele psychoterapii ukierunkowane są także czasem na podniesienie poziomu samokontroli, radzenie sobie ze stresem, podniesienie samooceny, poprawę zdolności tworzenia więzi
          z ludźmi oraz współpracy i komunikowania się z otoczeniem. Często cele terapii skupiają się na pogłębieniu poznania siebie i rozwoju osobowości.<br><br>
          
          Psychoterapię definiuje się również jako zbiór technik leczących /metod leczenia/ lub pomagających leczyć rozmaite schorzenia i problemy natury psychicznej. 
          Wspólną cechą wszystkich tych technik jest kontakt osoby z osobą (pacjenta z terapeutą) oraz ich wzajemna relacja <b>i aktywność</b>. To właśnie odróżnia psychoterapię od 
          leczenia czysto medycznego /np. farmakologicznego/. Dlatego też <b>elementem niezbędnym w terapii jest Twoje zaangażowanie i aktywny udział w leczeniu</b> a nie jedynie bierne 
          poddawanie się zabiegom leczniczym. To bardzo ważny warunek leczenia i zmiany. 
`,
};
