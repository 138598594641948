import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import Router from "./router";
import { Preloader } from "./components";
import "./App.css";

const App = () => {
  const [siteLoaded, setSiteLoaded] = useState<boolean>(false);

  useEffect(() => {
    const handleLoad = () => {
      setSiteLoaded(true);
    };

    if (document.readyState === "complete") {
      setSiteLoaded(true);
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <BrowserRouter>
      <Preloader isLoaded={siteLoaded} />
      <Router />
    </BrowserRouter>
  );
};

export default App;
