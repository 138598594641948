import "./description.css";

interface DescriptionType {
  name: string;
  title: string;
}

export const Description = ({ name, title }: DescriptionType) => (
  <div className="description">
    <h3>{name}</h3>
    <h4 dangerouslySetInnerHTML={{ __html: title }} />
  </div>
);
