import "./submit.css";

interface SubmitProps {
  label: string;
  hasOpacity: boolean;
}

export const Submit = ({ label, hasOpacity }: SubmitProps) => (
  <input
    type="submit"
    className={`submit ${hasOpacity ? "submit-opacity" : ""}`}
    value={label}
  />
);
