interface TopbarData {
  label: string;
  services: string[];
  button: {
    path: string;
    label: string;
  };
}

export const topbarData: TopbarData = {
  label: "Jesteśmy tu by Ci pomóc",
  services: [
    "psychoterapia indywidualna",
    "superwizja",
    "Psychoterapia dorosłych",
    "psychoterapia dzieci i młodzieży",
    "Psychoterapia rodzin i par",
    "hipnoza",
    "konsultacja",
    "szkolenia",
  ],
  button: {
    path: "/kontakt",
    label: "Umów wizytę",
  },
};
