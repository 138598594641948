import { InputTextProps } from "../../ui";

interface ContactDataContent {
  label: string;
  value: string;
  phoneNumber?: string;
}

interface ContactDataTypes {
  label: string;
  data: ContactDataContent[];
}

interface ValidationAlertsTextsTypes {
  wrongPhoneNumber: string;
  reCaptcha: string;
  somethingWentWrong: string;
}

export const contactData: ContactDataTypes = {
  label: "Skontaktuj się z nami",
  data: [
    {
      label: "Artur Kulig",
      value: "(+48) 605-27-64-65",
      phoneNumber: "+48605276465",
    },
    {
      label: "Marzena Kulig",
      value: "(+48) 605-04-19-65",
      phoneNumber: "+48605041965",
    },
    { label: "Adres", value: "Radlin k/Rybnika, ul. Głożyńska 200E" },
    { label: "Konto", value: "mBank - 07 1140 2004 0000 3002 6423 6676" },
  ],
};

export const contactFormInputs: InputTextProps[] = [
  {
    name: "names",
    placeholder: "Imię i nazwisko",
    readonly: false,
    type: "text",
    required: true,
  },
  {
    name: "mail",
    placeholder: "Adres e-mail",
    readonly: false,
    type: "email",
    required: true,
  },
  {
    name: "phone",
    placeholder: "Telefon (xxxxxxxxx)",
    readonly: false,
    type: "number",
    required: true,
  },
  {
    name: "subject",
    placeholder: "Temat",
    readonly: false,
    type: "text",
    required: true,
  },
];

export const validationAlertsText: ValidationAlertsTextsTypes = {
  wrongPhoneNumber:
    "Wprowadzony numer jest niepoprawny. Sprawdź jego poprawność i spróbuj ponownie. Wprowadzony numer musi mieć 9 znaków bez myślników i numeru kierunkowego",
  reCaptcha: "Musisz potwierdzić, że nie jesteś robotem!",
  somethingWentWrong: "Coś poszło nie tak. Spróbuj ponownie później.",
};
