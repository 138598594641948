import "./button.css";

interface ButtonProps {
  label: string;
  hasOpacity: boolean;
  path: string;
}

export const Button = ({ label, hasOpacity, path }: ButtonProps) => (
  <a href={path} className={`button ${hasOpacity ? "button-opacity" : ""}`}>
    {label}
  </a>
);
