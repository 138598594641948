import { therapistsData } from "../../shared";
import { Description, Heading, TherapistsImage } from "../../ui";
import "./therapists-list.css";

type BackgroundColor = "white" | "grey";

export const TherapistsList = ({
  backgroundColor,
}: {
  backgroundColor: BackgroundColor;
}) => (
  <div
    className={
      backgroundColor === "white" ? "therapists-white" : "therapists-grey"
    }
  >
    <div className="therapists-container">
      <Heading label="NASI TERAPEUCI I ICH USŁUGI" textColor="black" />
      <div className="therapists-container-content">
        {therapistsData.map((item, index) => (
          <a href={item.path} key={index} className="therapists">
            <TherapistsImage
              image={item.image}
              // certificates={item.certificates}
            />
            <Description name={item.name} title={item.title} />
          </a>
        ))}
      </div>
    </div>
  </div>
);
