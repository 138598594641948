import { ServiceType } from "../interfaces/therapists.type";

export const aboutMarzena: string = `<p style="margin-top: 0;"><strong>Jestem absolwentką Uniwersytetu Śląskiego w Katowicach</strong><strong>,</strong> dyplom magistra uzyskałam w 2000 roku. Wcześniej ukończyłam Kolegium Nauczycielskie na kierunku pedagogika. &nbsp;<strong>Od 2021r. certyfikowany psychoterapeuta poznawczo-behawioralna (nr certyfikatu 1111)</strong>, w trakcie intensywnego szkolenia w terapii dialektyczno-behawioralnej (DBT) w Behavioral Tech, Seattle, USA oraz w trakcie szkolenia specjalizacyjnego z Psychoterapii Dzieci i Młodzieży w Szpitalu Uniwersyteckim w Krakowie.<br>Ukończyłam dwuletnie szkolenie z zakresu hipnozy klinicznej w psychoterapii organizowane przez Towarzystwo Hipnozy Terapeutycznej i Badań nad Hipnozą. Ukończyłam r&oacute;wnież podstawowe szkolenie w zakresie terapii EMDR- czyli metody rekomendowanej przez Światową Organizację Zdrowia (WHO) do leczenia zaburzeń posttraumatycznych (PTSD) dla dzieci, młodzieży i os&oacute;b dorosłych, zar&oacute;wno w procesie indywidualnym jak i grupowym.<br>Jestem r&oacute;wnież absolwentką podyplomowych studi&oacute;w w&nbsp;zakresie logopedii i terapii pedagogicznej, neurologopedii, oligofrenopedagogiki, surdopedagogiki, tyflopedagogiki, wczesnego wspomagania rozwoju.</p>
<p><strong>Doświadczenie w pracy terapeutycznej</strong><strong>&nbsp;</strong></p>
<p>Doświadczenie zawodowe zdobywałam odbywając liczne staże m.in. w Zakładzie Terapii Rodzin w&nbsp;Katedrze Psychiatrii Collegium Medicum Uniwersytetu Jagiellońskiego, na oddziale psychiatrii dorosłych i oddziale psychiatrii dzieci i młodzieży w Klinice Psychiatrii Szpitala Uniwersyteckiego.</p>
<p>Przez ponad 20 lat pracowałam w Poradni Psychologiczno- Pedagogicznej w Czerwionce- Leszczynach gdzie prowadziłam szkolenia i warsztaty z zakresu pomocy psychologicznej, zaburzeń okresu dzieciństwa i adolescencji, komunikacji interpersonalnej, rozwiązywania konflikt&oacute;w, radzenia sobie ze stresem a także terapię &nbsp;indywidualną i grupową dzieci, jak r&oacute;wnież cykliczne konsultacje dla rodzin pacjent&oacute;w.</p>
<p>W kolejnych latach wsp&oacute;łpracowałam z Ośrodkiem Psychoterapii i Leczenia Uzależnień w Chwałowicach a także z Ośrodkiem Środowiskowej Opieki psychologicznej i Psychoterapeutycznej dla dzieci i Młodzieży.</p>
<p>W pracy stosuje metody psychoterapii oparte na dowodach naukowych (CBT, DBT, ACT, EMDR), łączę elementy r&oacute;żnych szk&oacute;ł terapeutycznych, dbając o to, by stosowane metody oddziaływań były adekwatne do indywidualnych potrzeb i zgłaszanych problem&oacute;w.</p>
<p><strong>Specjalizacja zawodowa</strong><strong>&nbsp;</strong></p>
<p>Specjalizuje się&nbsp;<strong>w terapii dzieci i młodzieży oraz os&oacute;b dorosłych</strong> z takimi problemami jak: trudności w zakresie regulacji emocji, zaburzenia nastroju, zaburzenia lękowe (napady&nbsp;paniki, uog&oacute;lnione zaburzenie lękowe, fobia społeczna, lęk o zdrowie, fobie specyficzne, PTSD) czy zaburzenia obsesyjno-kompulsyjne.</p>
<p>Nadal podnoszę swoje kwalifikacje przez udział w&nbsp;licznych szkoleniach, kongresach psychologiczno-psychoterapeutycznych.<br>Swoją pracę poddaje systematycznej superwizji.</p>`;

export const servicesMarzena: ServiceType[] = [
  {
    title: "Hipnoterapia",
    content: `<p><span>Hipnoza jest wykorzystywana w psychologii klinicznej i psychiatrii między innymi jako jedna z metod diagnozowania i leczenia zaburzeń nerwicowych, zaburzeń lękowych, a także przy zmniejszaniu b&oacute;lu oraz w terapii niekt&oacute;rych uzależnień.&nbsp;</span></p>
<p><span>W praktyce hipnoterapeuta wprowadza klienta w <strong>stan relaksu&nbsp;</strong>i wykorzystuje moc jego wyobraźni, mając do wyboru szeroką gamę technik &ndash; od podejścia pasywnego (<strong>opowiadanie historii, wprowadzania indywidualnie dobranych metafor i symboli, sugestie bezpośrednie</strong>) do aktywnego (<strong>hipnoza konwersacyjna</strong>). Bardzo istotnym elementem hipnoterapii jest zbudowanie odpowiedniej <strong>relacji pomiędzy klientem a terapeutą</strong>, opartej na zaufaniu i poczuciu bezpieczeństwa. Jest to szczeg&oacute;lnie ważne, ponieważ autorytet praktyka i zaufanie do niego w znacznym stopniu przekładają się na efekty terapeutyczne. Nie&nbsp;jest to jednak narzędzie, kt&oacute;re przerzuca na terapeutę odpowiedzialność za zmianę, ponieważ jest to wsp&oacute;lna praca, wymagająca otwartości, gotowości i odwagi, aby zmierzyć się ze&nbsp;swoimi ograniczeniami.</span></p>
<p><strong><span>Hipnoza nie odbiera kontroli nad wewnętrznymi procesami i stanami, wręcz przeciwnie, zwiększa ją</span></strong><span>. Z tego powodu nawet mała doza oporu ze strony hipnotyzowanego może znacznie ograniczyć możliwości uzyskania oczekiwanych efekt&oacute;w terapii.</span></p>
<p><span>Hipnoterapia, w odr&oacute;żnieniu od innych nurt&oacute;w terapeutycznych, jest uznawana za <strong>kr&oacute;tkoterminowe podejście</strong>, w kt&oacute;rym korzystna zmiana, jeśli ma nastąpić, powinna pojawić się relatywnie szybko.</span></p>

<p><strong><span>Kto może zostać zahipnotyzowany?</span></strong></p>
<p><span>Każdy człowiek codziennie doświadcza spontanicznych stan&oacute;w transowych, więc może r&oacute;wnież zostać zahipnotyzowany na życzenie. Należy jednak zaznaczyć, że <strong>niekt&oacute;rzy ludzie łatwiej poddają się hipnozie niż inni</strong>, ponieważ wejście w ten stan zależy od ich własnej woli. Chęć wejścia w trans zależy z kolei od kilku czynnik&oacute;w, z kt&oacute;rych ważniejsze to wiara i zaufanie w stosunku do terapeuty oraz samej metody.</span></p>

<p><strong><span>Jaki poziom transu jest wymagany do uzyskania przez kogoś korzystnej zmiany?</span></strong></p>
<p><span>Istnieje tutaj pewna rozbieżność co do odpowiedzi, jednak większość badaczy przyznaje, że <strong>rzeczywisty poziom (lub głębokość) doświadczanego transu nie ma związku z&nbsp;możliwościami uzyskania korzystnych zmian</strong>. W praktyce oznacza to, że nawet jeśli osoba nie czuje się zahipnotyzowana, to po upłynięciu odpowiedniej ilości czasu może odczuć pożądane efekty terapii. Kwestia czasu jest szczeg&oacute;lnie istotna w dzisiejszym społeczeństwie, w kt&oacute;rym dominuje przekonanie, że zaspokojenie każdej potrzeby musi nastąpić błyskawicznie. Hipnoterapia jest wyjątkowo efektywna, ale nie jest magią. Jednak przy obecności odpowiednich czynnik&oacute;w, na kt&oacute;re składają się właściwy czas i kompetentny terapeuta, z kt&oacute;rym klient czuje się bezpiecznie i pewnie, wszystkie (realistyczne) cele są możliwe do osiągnięcia.</span></p>

<p><strong><span>Kto może skorzystać z hipnoterapii?</span></strong></p>
<p><strong><span>Hipnoza jest dla każdego,&nbsp;</span></strong><span>kto pragnie zmiany terapeutycznej, czyli przywr&oacute;cenia r&oacute;wnowagi wewnętrznej. Jest r&oacute;wnież dla tych, kt&oacute;rzy poprzez głębokie zajrzenie w siebie chcą udoskonalić swoje życie, odkryć uśpiony potencjał, swoje talenty i odnaleźć najbardziej sp&oacute;jną z całą swoją istotą ścieżkę życiową.</span></p>
<p><strong><span>Moimi klientami</span></strong><span>&nbsp;są ludzie zajmujący się na co dzień r&oacute;żnymi rzeczami. Są wśr&oacute;d nich lekarze, terapeuci, prawnicy, aktorzy, dziennikarze, pisarze, sportowcy, ale też bezrobotni, kt&oacute;rzy poszukują swojego powołania.&nbsp;</span></p>
<p><strong><span>Większość ludzi może bez najmniejszego ryzyka poddać się hipnozie i hipnoterapii</span></strong><span>. <strong>Są jednak pewne przeciwwskazania&nbsp;</strong>do tego typu pracy, wśr&oacute;d kt&oacute;rych wymienia się: schizofrenię, patologiczne zaburzenia osobowości, psychozę, epilepsję, narkolepsję, chorobę dwubiegunową oraz ciężkie zaburzenia depresyjne z tendencjami samob&oacute;jczymi. Przeciwwskazaniem mogą być także poważne choroby serca. R&oacute;wnież leki psychotropowe, antydepresyjne lub inne zmieniające świadomość, wykluczają hipnoterapię. Ponadto osoby, kt&oacute;rych pespektywa poddania się hipnozie napełnia silnym lękiem &ndash; pomimo przedstawienia im prawdziwej natury tego narzędzia &ndash; powinny poszukać dla siebie innej formy terapii.</span></p>

<p><strong><span>W czym może pom&oacute;c hipnoterapia?</span></strong></p>
<p><strong><span>Wszystkie problemy emocjonalne czy też fizyczne, kt&oacute;rymi zajmuje się tradycyjna medycyna czy psychoterapia mogą być rozwiązane lub znacząco wspierane drogą hipnoterapii.&nbsp;</span></strong><span>Ona sięga nawet głębiej, ponieważ dociera do przyczyn problemu, do&nbsp;kt&oacute;rych inną metodą często nie da się dotrzeć. Jest też niezr&oacute;wnana w odkrywaniu nieodkrytych zasob&oacute;w siły, szczęścia, motywacji, energii, akceptacji, dzięki kt&oacute;rym możemy rozkwitnąć i podążać ku najwyższemu potencjałowi. Hipnoterapia może także aktywować i&nbsp;przyspieszać wrodzone zdolności naszego organizmu do samouzdrawiania.</span></p>

<p><strong><span>Poniższa lista zawiera tematy, z kt&oacute;rymi klienci najczęściej zgłaszają się na hipnoterapię.</span></strong></p>
<ul type="disc">
    <li><span>poprawa stanu zdrowia fizycznego i mentalnego,</span></li>
    <li><span>relaksacja i usuwanie skutk&oacute;w stresu,</span></li>
    <li><span>zwalczanie lęk&oacute;w i fobii,</span></li>
    <li><span>wzmacnianie pewności siebie i poczucia własnej wartości,</span></li>
    <li><span>poprawa motywacji i szybsza realizacja cel&oacute;w,</span></li>
    <li><span>rozwijanie zdolności komunikacyjnych interpersonalnych i intrapersonalnych,</span></li>
    <li><span>rozwiązywanie problem&oacute;w w relacjach prywatnych i zawodowych,</span></li>
    <li><span>uzdrawianie zranionego serca,</span></li>
    <li><span>leczenie depresji i zaburzeń emocjonalnych,</span></li>
    <li><span>leczenie stresu pourazowego,</span></li>
    <li><span>zmiana negatywnych myśli, niekorzystnych reakcji emocjonalnych i wzorc&oacute;w zachowań,</span></li>
    <li><span>uzdrawianie przeszłości i programowanie wymarzonej przyszłości,</span></li>
    <li><span>eliminacja bezsenności,</span></li>
    <li><span>uwalnianie od nałog&oacute;w i niekorzystnych nawyk&oacute;w,</span></li>
    <li><span>hipnotyczne odchudzanie,</span></li>
    <li><span>leczenie zaburzeń odżywiania,</span></li>
    <li><span>eliminacja b&oacute;lu tymczasowego i chronicznego,</span></li>
    <li><span>znieczulenie og&oacute;lne i miejscowe w trakcie operacji i porod&oacute;w,</span></li>
    <li><span>przygotowanie do operacji,</span></li>
    <li><span>przyspieszenie rehabilitacji po r&oacute;żnego typu urazach fizycznych,</span></li>
    <li><span>osiąganie szczytowej formy sportowej,</span></li>
    <li><span>poprawa koncentracji,</span></li>
    <li><span>wzmacnianie pamięci,</span></li>
    <li><span>przygotowanie do egzaminu lub innego wydarzenia,</span></li>
    <li><span>pokonywanie problemu prokrastynacji (tendencji do nieustannego przekładania pewnych czynności na p&oacute;źniej),</span></li>
    <li><span>poszukiwanie zagubionych przedmiot&oacute;w lub jakiś informacji,</span></li>
    <li><span>wydobywanie pełni potencjału w pisaniu, sztuce i innego typu zajęciach wymagających kreatywności,</span></li>
    <li><span>odkrywanie własnych talent&oacute;w,</span></li>
    <li><span>wydobywanie i wzmacnianie zasob&oacute;w,</span></li>
    <li><span>szybsza nauka i doskonalenie r&oacute;żnych umiejętności,</span></li>
    <li><span>odnajdywanie swojej misji życiowej,</span></li>
    <li><span>szeroko pojęty samorozw&oacute;j.</span></li>
</ul>

<p><strong><span>Czy istnieją niebezpieczeństwa?</span></strong></p>
<p><span>Hipnoza w czystej postaci jest potężnym narzędziem etycznego wpływu. To najstarsza metoda leczenia człowieka, w pełni naturalna, ponieważ bazuje na wrodzonym w każdym człowieku potencjale samouzdrawiania.</span></p>
<p><span>Jednak każde efektywne narzędzie może być użyte spos&oacute;b nieetyczny. Hipnoza jest jak pudełko zapałek. W dobrych rękach może być źr&oacute;dłem ciepła, światła, energii, ale w złych rękach może poparzyć albo spalić. Hipnoza w odpowiednich rękach jest lekarstwem na wiele dolegliwości i problem&oacute;w, może dostarczać wspaniałej rozrywki oraz głęboko relaksować. Niestety, kiedy wpadnie w nieodpowiednie ręce, może doprowadzić do mentalnego dyskomfortu, stworzyć stres psychologiczny czy pogorszyć zaburzenia psychologiczne.</span></p>
<p><span>Nie jest jednak możliwe zmuszenie zahipnotyzowanej osoby do zrobienia czegokolwiek wbrew jej woli, systemowi wartości lub poglądom religijnym. Należy r&oacute;wnież pamiętać, że&nbsp;każdy hipnoterapeuta posiada sw&oacute;j zestaw narzędzi, technik, a także dysponuje inną wiedzą i doświadczeniem, co generuje często ekstremalnie odmienne rezultaty w zakresie efektywności i tempa zmian. Zdarza się, że klient doświadcza słabej jakości usług hipnoterapeutycznych, kt&oacute;re nie przynoszą żadnego efektu i w rezultacie zraża się do metody, odbierając sobie możliwość szybkiego powrotu do zdrowia. W moim odczuciu właśnie taki niefortunny pierwszy kontakt z hipnozą stanowi największe niebezpieczeństwo dla os&oacute;b poszukujących wsparcia terapeutycznego.</span></p>

<p><strong><em><span>Hipnoterapia trwa 50 minut, kosztuje 180 zł.</span></em></strong></p>`,
  },
  {
    title: "Psychoterapia on-line",
    content: `<p><span>Psychoterapia online z wykorzystaniem komunikatora Skype adresowana jest gł&oacute;wnie do&nbsp;os&oacute;b, kt&oacute;re z r&oacute;żnych względ&oacute;w nie są w stanie uczęszczać na psychoterapię stacjonarną. Osoba, kt&oacute;ra nie ma możliwości pojawić się u mnie w gabinecie i odbyć regularnej terapii, nie musi z niej rezygnować. Internetowe spotkania przynoszą taki sam rezultat, co osobista rozmowa, zwłaszcza przy użyciu kamery internetowej.</span></p>
            <p><strong><span>Dla kogo szczeg&oacute;lnie rekomenduję ten rodzaj spotkania?</span></strong></p>
            <ul style="list-style-type: disc;margin-left:26px;">
                <li><span>Dla os&oacute;b zapracowanych, kt&oacute;re nie mogą odbyć terapii w standardowych godzinach pracy.</span></li>
                <li><span>Dla tych, kt&oacute;rzy mieszkają daleko od ośrodk&oacute;w udzielających pomocy psychologicznej.</span></li>
                <li><span>Dla rodzin oraz par, kt&oacute;re z r&oacute;żnych przyczyn mieszkają daleko od siebie.</span></li>
                <li><span>Dla os&oacute;b przebywających za granicą.</span></li>
            </ul>
            <p><strong><span>Wszystko czego potrzebujesz do odbycia terapii online to:</span></strong></p>
            <ul style="list-style-type: disc;margin-left:26px;">
                <li><span>zainstalowany program Skype,&nbsp;</span></li>
                <li><span>kamerka internetowa,&nbsp;</span></li>
                <li><span>mikrofon,&nbsp;</span></li>
                <li><span>stabilne i szybkie łączę internetowe (zalecane jest łącze szerokopasmowe o&nbsp;przepustowości 2 Mb/s)</span></li>
            </ul>
            
            <p><strong><em><span>Spotkanie konsultacyjne, psychoterapia indywidualna trwa 50 minut, kosztuje 180 zł.</span></em></strong></p>`,
  },
  {
    title: "Psychoterapia Indywidualna",
    content: `<p><span>R&oacute;żne powody, oczekiwania i potrzeby motywują nas do poszukiwania profesjonalnej pomocy jaką oferuje psychoterapia indywidualna. Jej celem jest wprowadzenie w życiu pacjenta pozytywnych zmian, kt&oacute;re będą się przekładały na jego funkcjonowanie w obszarze prywatnym, społecznym i zawodowym. Zdaniem wielu pacjent&oacute;w terapia indywidualna to narzędzie do osiągnięcia wewnętrznego spokoju i czerpania radości z życia.</span><br><br></p>
            <p><strong><span>Na czym to polega?</span></strong></p>
            <p><span>Terapia indywidualna to szczeg&oacute;lny rodzaj psychoterapii, w trakcie kt&oacute;rej pomiędzy pacjentem a terapeutą tworzy się więź emocjonalna związana z pełnieniem wyznaczonych r&oacute;l. Więź oraz role mają za zadanie osiągnięcie wsp&oacute;lnego celu. Z perspektywy pacjenta terapeuta bardzo często staje się tzw. znaczącym innym &ndash; osobą ważną, kt&oacute;ra wypełnia deficyty emocjonalne pacjenta. W relacji z terapeutą odtwarza schematy relacji, jakie miał z innymi znaczącymi osobami.</span></p>`,
  },
  {
    title: "Psychoterapia CBT",
    content: `<p><span>Psychoterapia CBT (z ang. cognitive&ndash;behavioral therapy) to metoda leczenia zaburzeń psychicznych, trudności emocjonalnych oraz problematycznych zachowań. Jej celem jest poprawa jakości życia pacjenta poprzez modyfikację jego zachowań i sposobu myślenia. W&nbsp;praktyce psychoterapia poznawczo-behawioralna obejmuje grupę terapii o wsp&oacute;lnych założeniach teoretycznych, w ramach kt&oacute;rych uznaje się, że objawy psychopatologiczne, dysfunkcjonalne zachowania i emocje jednostki można wyjaśnić na podstawie teorii uczenia się i uwzględnienia pośredniczącej roli proces&oacute;w poznawczych.</span></p>
                <p><strong><span>Psychoterapia CBT &ndash; cechy</span></strong></p>
                <p><span>Ze względu na fakt, że m&oacute;wimy tu o grupie terapii bazujących na wsp&oacute;lnych założeniach, musimy pamiętać, że psychoterapia poznawczo-behawioralna może się od siebie r&oacute;żnić. R&oacute;żnice te będą wynikały z cech jednostkowych pacjenta, specyfiki jego problemu, jak&nbsp;r&oacute;wnież narzędzi terapii, jakie w danym przypadku wybierze terapeuta CBT. Mimo r&oacute;żnic w procesie terapeutycznym CBT można wskazać pewne cechy wsp&oacute;lne, a mianowicie:</span></p>
                <ul type="disc">
                    <li><span>oparcie terapii na wsp&oacute;łpracy między terapeutą a pacjentem,</span></li>
                    <li><span>zorientowanie na rozwiązanie problemu,</span></li>
                    <li><span>skupienie na teraźniejszości,</span></li>
                    <li><span>ograniczenie w czasie,</span></li>
                    <li><span>stosowanie odpowiednich technik terapeutycznych.</span></li>
                </ul>
                <p><span>Terapie określane mianem terapii CBT obejmują m.in.:</span></p>
                <ul type="disc">
                    <li><span>terapię akceptacji i zaangażowania,</span></li>
                    <li><span>terapię dialektyczno-behawioralną,</span></li>
                    <li><span>system psychoterapii oparty na analizie behawioralnej i poznawczej,</span></li>
                    <li><span>psychoterapię opartą na analizie funkcjonalnej,</span></li>
                    <li><span>integracyjną terapię behawioralną par,</span></li>
                    <li><span>terapię poznawczą opartą na uważności,</span></li>
                    <li><span>terapię schemat&oacute;w.</span></li>
                </ul>
                
                <p><strong><em><span>Spotkanie konsultacyjne, psychoterapia indywidualna trwa 50 minut, kosztuje 180 zł.</span></em></strong></p>`,
  },
  {
    title: "Psychoterapia dzieci",
    content: `<p><span>Na początku konsultacje odbywają się z rodzicami. To oni najlepiej znają swoje dziecko, widzą je na co dzień i obserwują zmiany, kt&oacute;re w nim zachodzą. Ich rozumienie tego, co się dzieje z dzieckiem, hipotezy na temat przyczyn niepokojących zachowań, własne uczucia są kluczowym źr&oacute;dłem informacji do zrozumienia jego stanu. Jeżeli jest to możliwe, proponuję wsp&oacute;lne spotkania z obydwojgiem rodzic&oacute;w, co nie wyklucza spotkań indywidualnych z powodu szczeg&oacute;lnych okoliczności (nieobecność jednego z rodzic&oacute;w, rozw&oacute;d). Dopiero po odbyciu spotkań z rodzicami i wsp&oacute;lnych rozm&oacute;w o teraźniejszości i&nbsp;przeszłości ich dziecka, odbywają się konsultacje z nim samym. Ilość tych spotkań uzależniona jest od wielu czynnik&oacute;w, przede wszystkim od złożoności problemu i możliwości jego nazwania, ale zwykle sprowadza się do 2-3 konsultacji. Następnie ma miejsce rozmowa z rodzicami, na kt&oacute;rej omawiane są konsultacje i proponowane rozwiązania. Każda forma pomocy dziecku wymaga zgody rodzic&oacute;w. Bardzo ważne jest wsparcie zar&oacute;wno ze strony matki, jak i ojca, stąd wymagana jest ich zgoda na rozpoczęcie psychoterapii, Treningu Zastępowania Agresji bądź Treningu Umiejętności Społecznych.</span></p>
            <p><span>Psychoterapia dzieci opiera się na połączeniu formy zabawy i rozmowy. Ogromne znaczenie ma uwzględnienie otoczenia dziecka, w praktyce najczęściej rodzic&oacute;w i najbliższych opiekun&oacute;w. Rodzice szukający pomocy dla dziecka sami są bardzo zaniepokojeni. Często pojawia się lęk przed oceną, poczucie winy, samooskarżenia. Dziecięce trudności nasilają się w przełomowych rozwojowo momentach np. p&oacute;jście do przedszkola czy szkoły. Wtedy dodatkowo wzmaga się poczucie presji społecznej, ponieważ trudności nie rozgrywają się jedynie w domu, ale oznaczają otrzymywanie niepokojących informacji od opiekun&oacute;w lub nauczycieli dziecka.</span></p>
            <p><span>Decyzja o podjęciu konsultacji ze specjalistą bywa znacznie trudniejsza dla rodzic&oacute;w niż dla samego dziecka. Bardzo często rodzi się silna potrzeba, aby &bdquo;usunąć&rdquo; problem jak najszybciej. Warto jednak przyjrzeć się sytuacji dziecka spokojnie i wnikliwie. Z reguły oznacza to potrzebę spotkań z rodzicami, kt&oacute;re dają lepszy wgląd w sytuację całej rodziny i poprzedzają indywidualną pracę z dzieckiem. Rolą terapeuty jest wspieranie rodzic&oacute;w w rozumieniu proces&oacute;w zachodzących w dziecku i w jego rodzinie, a nie ocenianie. Rodzice, kt&oacute;rzy sami potrafią skorzystać z pomocy i radzą sobie z własnymi niepokojami oraz uczuciami, będą mieli więcej siły, a także uwagi dla dziecka. Przypomina to instrukcję bezpieczeństwa wydawaną przez stewardesy w samolotach. Kiedy zachodzi konieczność nałożenia masek tlenowych, to najpierw rodzic zakłada maskę sobie, a potem dopiero dziecku. Taka kolejność jest ważna, bo jeśli rodzicowi zabraknie tlenu i sił, nie będzie m&oacute;gł pom&oacute;c dziecku. Optymalny rozw&oacute;j dla dziecka wymagającego terapii daje zar&oacute;wno praca z samym dzieckiem, jak i wsp&oacute;łpraca z jego rodzicami.</span></p>
            <p><span>W trakcie terapii terapeuta obserwuje lub w uzasadnionych sytuacjach włącza się w swobodną aktywność dziecka, będącą odpowiednikiem wolnych skojarzeń w rozmowie z dorosłym. Obserwując zabawę dziecka i atmosferę emocjonalną, stara się zrozumieć, co dziecko chce przekazać otoczeniu. Małe dzieci nie potrafią m&oacute;wić wprost i nazywać stan&oacute;w emocjonalnych, w kt&oacute;rych się znajdują i często komunikują je poprzez swoje zachowania, formę zabawy czy poprzez ciało. Często też wyobrażają sobie zasady rządzące światem w zupełnie odmienny spos&oacute;b niż dorośli. Zdarza się, że dzieci nie mogą z r&oacute;żnych względ&oacute;w otrzymać odpowiedzi na wysyłane sygnały, wtedy nasilają zachowania, kt&oacute;re mają na celu zwr&oacute;cenie uwagi rodzic&oacute;w i uzyskanie pomocy. Każde dziecko jest inne, ale można wyodrębnić pewne grupy zachowań, z kt&oacute;rymi warto zwr&oacute;cić się po pomoc do psychoterapeuty:</span></p>
            <ul type="disc">
                <li><span>nadpobudliwość, trudności w zachowaniu i utrzymaniu emocji np. napady złości,</span></li>
                <li><span>wzmożona lękowość, wycofanie, moczenie nocne,</span></li>
                <li><span>trudności w kontaktach r&oacute;wieśniczych,</span></li>
                <li><span>fobie szkolne,</span></li>
                <li><span>trudności z rozstawaniem z rodzicami np. u progu przedszkola lub szkoły,</span></li>
                <li><span>trudności w budowaniu trwałych i bezpiecznych więzi &ndash; dziecko jest nieufne, ucieka od kontaktu i bliskości,</span></li>
                <li><span>dziecko ma koszmary nocne i trudności ze spaniem,</span></li>
                <li><span>dziecko reaguje chorowaniem lub b&oacute;lem fizycznym w sytuacjach stresogennych,</span></li>
                <li><span>dziecko przejawia zachowania autoagresywne np. okaleczanie się,</span></li>
                <li><span>odreagowuje stres poprzez jedzenie np. objadanie się, utratę apetytu, zmierza w&nbsp;kierunku anoreksji i bulimii,</span></li>
                <li><span>zaburzenia ze spektrum autyzmu np. zesp&oacute;ł Aspergera.</span><br><br></li>
            </ul>
            <p><span>Psychoterapia ma pom&oacute;c dziecku w lepszym radzeniu sobie z ogromnymi wyzwaniami rozwojowymi &ndash; radzeniu sobie z rywalizacją i relacjami r&oacute;wieśniczymi, bezpieczną separacją od rodzic&oacute;w, rozwijaniu własnego indywidualnego potencjału. Nikt nie zastąpi rodzic&oacute;w w ich codziennej miłości i opiece nad dzieckiem. W psychoterapii dzieci terapeuta zawsze potrzebuje wsp&oacute;łpracy ze strony rodzic&oacute;w, kt&oacute;rzy gotowi są do dodatkowej pracy, aby stworzyć dziecku środowisko najbardziej optymalnego rozwoju.</span></p>
            <p><strong><em><span>Spotkanie konsultacyjne, psychoterapia indywidualna trwa 50 minut, kosztuje 180 zł.</span></em></strong></p>`,
  },
  {
    title: "Psychoterapia młodzieży",
    content: `<p><span>Okres dojrzewania jest niedocenianym, niezbędnym, a jednocześnie bardzo trudnym czasem w życiu człowieka. Niekt&oacute;rzy psychoanalitycy uważają, że jest to okres, w kt&oacute;rym problemy, nieprzepracowane we wcześniejszych etapach rozwoju, dostają szansę na ponowne ich uruchomienie, przeżycie i przepracowanie. Chodzi tu w szczeg&oacute;lności o czas indywiduacji i separacji. Uniezależnienie się od rodzic&oacute;w i budowanie własnej tożsamości jest rozwojowo bardzo ważne i konieczne. Zdarza się w tym okresie, że nieporozumienia w&nbsp;rodzinie są tak silne, a zachowania autoagresywne dziecka tak nasilone, że podjęcie terapii staje się konieczne&nbsp;do bezpiecznego przejścia w czas dorosłości. Warto skorzystać z konsultacji i&nbsp;psychoterapii dla młodzieży, jeśli:</span></p>
            <ul type="disc">
                <li><span>konflikty w rodzinie są szczeg&oacute;lnie raniące dla wszystkich stron,</span></li>
                <li><span>nastolatek ma trudności w kontaktach z r&oacute;wieśnikami lub szkolne,</span></li>
                <li><span>u nastolatka dominuje znacznie zaniżona samoocena,</span></li>
                <li><span>nastolatek jest wycofany i samotny,</span></li>
                <li><span>u nastolatka pojawiają się samookaleczenia lub myśli samob&oacute;jcze,</span></li>
                <li><span>nastolatek przeżywa zaw&oacute;d miłosny i nie potrafi sobie z nim poradzić,</span></li>
                <li><span>nastolatek nie radzi sobie z emocjami&nbsp;</span></li>
                <li><span>nastolatek nie akceptuje swojego ciała i zmian związanych z dojrzewaniem,</span></li>
                <li><span>u nastolatka pojawiają się zaburzenia odżywiania lub snu.</span></li>
            </ul>
            <p><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Psychoterapia prowadzona z młodzieżą uwzględnia i chroni rodzącą się niezależność młodego człowieka, dlatego po 16. roku jego życia rodzice w zasadzie nie mają wglądu w&nbsp;terapię swojego dziecka. Do jej podjęcia konieczna jest motywacja i potrzeba nastolatka. Psychoterapeuta ma obowiązek zachować w tajemnicy to, co jest przedmiotem rozmowy. Jest to niezwykle delikatna sytuacja ze względu na konieczne wsparcie i&nbsp;zaangażowanie opiekun&oacute;w np. w zakresie faktycznej zależności życiowej dziecka od&nbsp;rodzic&oacute;w, w tym finansowania terapii. Zachowanie poufności jest jednak niezbędne dla&nbsp;zbudowania bezpiecznej relacji terapeutycznej i pozwala młodemu pacjentowi na&nbsp;otwartość i szczerość. Niekiedy adolescent lub rodzice mają potrzebę konsultacji. Odbywa się to wtedy na zasadach uzgodnionych&nbsp;przez wszystkie strony z&nbsp;zastrzeżeniem obowiązującej terapeutę tajemnicy.</span></p>
            
            <p><strong><em><span>Spotkanie konsultacyjne, psychoterapia indywidualna trwa 50 minut, kosztuje 180 zł.<br>&nbsp;</span></em></strong></p>`,
  },
  {
    title: "Terapia Rodzin",
    content: `<p><span>Ten rodzaj psychoterapii przeznaczony jest dla rodzin przeżywających problemy, z&nbsp;kt&oacute;rymi nie są sobie w stanie poradzić samodzielnie. Terapia pomaga rodzinom będącym w&nbsp;momencie kryzysu, kt&oacute;ry może mieć r&oacute;żne formy i wiązać się na przykład z pojawieniem się nowego członka rodziny, chorobą psychiczną (depresja, zaburzenia nerwicowe, lęk, fobie, anoreksja, bulimia, zaburzenia osobowości, uzależnienia od środk&oacute;w psychoaktywnych, schizofrenia, zaburzenia nastroju) lub fizyczną jednego z członk&oacute;w rodziny, drastyczną zmianą w funkcjonowaniu rodziny, zmianą warunk&oacute;w życiowych, problemami z&nbsp;dorastającymi dziećmi, odejściem dzieci na studia, itd. Terapia rodzinna pomaga r&oacute;wnież w&nbsp;sytuacjach konflikt&oacute;w pomiędzy członkami rodziny oraz jest dobrym sposobem pracy w&nbsp;sytuacji impasu w związku.</span></p>
            <p>Terapię rodzin prowadzę w oparciu o podejście systemowe.</p>
            <p><strong><em><span>Spotkanie konsultacyjne, psychoterapia indywidualna trwa 50 minut, kosztuje 200 zł.</span></em></strong></p>`,
  },
];

export const certificatesMarzena = [
  "Certyfikowana psychoterapeutka poznawczo-behawioralna (nr certyfikatu 1111)",
];
