import { TherapetistsData } from "../../shared";
import { Heading, Spoiler, TherapistsImage } from "../../ui";
import "./therapists.css";

export const Therapists = ({ data }: { data: TherapetistsData }) => {
  const { name, certificates, image, content, services } = data;
  return (
    <>
      <div className="content-therapist content-therapist-margin">
        <div className="content-therapist-box">
          <TherapistsImage image={image} />
        </div>
        <div className="content-therapist-box">
          <Heading label={name} textColor="black" />
          <p dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
      <div className="spoilers-container">
        {services.map((service, index) => (
          <Spoiler
            label={service.title}
            content={service.content}
            key={index}
          />
        ))}
      </div>
    </>
  );
};
