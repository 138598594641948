import "./therapists-image.css";

interface Therapists {
  image: string;
  certificates?: string[];
}

export const TherapistsImage = ({ image, certificates }: Therapists) => (
  <div className="therapists-item">
    {certificates && (
      <ul>
        <h5>Posiadane certyfikaty</h5>
        {certificates.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    )}
    <div className="image-container">
      <img src={image} alt="therapist" />
    </div>
  </div>
);
