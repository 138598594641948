import { useState } from "react";
import { useLocation } from "react-router-dom";
import { images } from "../../assets/img";
import { HamburgerToggler, NavigationUrl, PhoneBox } from "../../ui";
import { navigationLinks } from "./navigation.data";
import "./navigation.css";

export const Navigation = () => {
  const [isOpenMobileNav, setMobileNavStatus] = useState<boolean>(false);

  const location = useLocation();
  const actuallyPath = location.pathname;

  const showMenu = () => setMobileNavStatus((prevValue) => !prevValue);

  return (
    <header className="header-container">
      <div className="header-container-content">
        <a href="/">
          <img src={images.logo} alt="logo" />
        </a>
        <nav className={isOpenMobileNav ? "nav-active" : ""}>
          {navigationLinks.map((url, index) => (
            <NavigationUrl
              label={url.label}
              path={url.path}
              isActive={actuallyPath === url.path}
              key={index}
            />
          ))}
        </nav>
        <PhoneBox label="605-27-64-65" phoneNumber="+48605276465" />
        <HamburgerToggler showMenu={showMenu} isActive={isOpenMobileNav} />
      </div>
    </header>
  );
};
