interface NavigationLinks {
  label: string;
  path: string;
}

export const navigationLinks: NavigationLinks[] = [
  { label: "Strona głowna", path: "/" },
  { label: "o gabinecie", path: "/gabinet" },
  { label: "psychoterapia", path: "/psychoterapia" },
  { label: "terapeuci", path: "/terapeuci" },
  { label: "kontakt", path: "/kontakt" },
];
