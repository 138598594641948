import { useLocation } from "react-router-dom";
import { PostType, about, privacy, psychotheraphy, rodo } from "../shared";
import { Footer, Map, Navigation, Post } from "../components";

export const SinglePage = () => {
  const location = useLocation();
  const pathname = location.pathname as PostsTypes;

  enum PostsTypes {
    About = "/gabinet",
    Psychotheraphy = "/psychoterapia",
    Rodo = "/rodo",
    Privacy = "/polityka-prywatnosci",
  }

  const posts: { [key in PostsTypes]: PostType } = {
    [PostsTypes.About]: about,
    [PostsTypes.Psychotheraphy]: psychotheraphy,
    [PostsTypes.Rodo]: rodo,
    [PostsTypes.Privacy]: privacy,
  };

  const pageContent = posts[pathname];

  return (
    <>
      <Navigation />
      <Post label={pageContent.label} content={pageContent.text} isPost />
      <Map />
      <Footer />
    </>
  );
};
