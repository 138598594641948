import { PostType } from "../interfaces/post.type";

export const about: PostType = {
  label: "O gabinecie",
  text: `Gabinet psychoterapii istnieje od 2000 roku. Jest miejscem przyjaznym dla pacjentów i dostosowanym również dla osób niepełnosprawnych. 
        Położony w zacisznej okolicy Radlina w otoczeniu kwiatów i roślin. Na miejscu dostępny jest prywatny parking.<br><br>

        Oferta terapeutyczna skierowana jest dla osób dorosłych, dzieci i młodzieży oraz par.<br>
        W ramach działalności gabinetu psychoterapii prowadzona jest również superwizja dla terapeutów w formie indywidualnej i grupowej, in-vivo i on-line.`,
};
