interface FooterPath {
  label: string;
  path: string;
}

interface FooterPaths {
  leftSide: FooterPath[];
  rightSide: FooterPath[];
}

export const copyright: string =
  "CREATED BY DOMINIK LISZKA - 2022 - Gabinet Psychoterapii - ALL RIGHTS RESERVED";

export const leftSide: FooterPath[] = [
  {
    label: "Polskie Towarzystwo Psychiatryczne",
    path: "https://psychiatria.org.pl/",
  },
  {
    label: "Polskie Towarzystwo Psychologiczne",
    path: "https://psych.org.pl/",
  },
  {
    label: "Sekcja Naukowa Terapii Rodzin",
    path: "http://sntr.org.pl/",
  },
  {
    label: "Sekcja Naukowa Psychoterapii PTP",
    path: "https://sekcjanaukowapsychoterapii.org/",
  },
];

export const rightSide: FooterPath[] = [
  {
    label: "Polskie Stowarzyszenie Balintowskie",
    path: "http://balint.pl/",
  },
  // {
  //   label: "Superwizja na Śląsku",
  //   path: "http://superwizja.slask.pl/",
  // },
  {
    label: "Śląskie Stowarzyszenie Psychoterapeutów",
    path: "http://psychoterapia-silesia.org.pl/stowarzyszenie_psychoterapeutow.html",
  },
  {
    label: "Zasady przetwarzania danych osobowych w Gabinecie Psychoterapii",
    path: "/rodo",
  },
  {
    label: "Polityka prywatnosci",
    path: "/polityka-prywatnosci",
  },
];

export const footerPaths: FooterPaths = { rightSide, leftSide };
