import { Footer, Map, Navigation, TherapistsList } from "../components";

export const TherapistsPage = () => (
  <>
    <Navigation />
    <TherapistsList backgroundColor="white" />
    <Map />
    <Footer />
  </>
);
