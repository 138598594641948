import "./phone-box.css";
import { icons } from "../../assets/icons";

interface PhoneBoxProps {
  label: string;
  phoneNumber: string;
}

export const PhoneBox = ({ label, phoneNumber }: PhoneBoxProps) => (
  <div className="phone-box-container">
    <div className="phone-box">
      <img src={icons.phone} alt="phone" />
      <a href={`tel:${phoneNumber}`}>{label}</a>
    </div>
  </div>
);
