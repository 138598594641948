import "./heading.css";

type TextColor = "white" | "black";

interface HeadingProps {
  label: string;
  textColor: TextColor;
}

export const Heading = ({ label, textColor }: HeadingProps) => (
  <h2 className={textColor === "black" ? "heading-black" : "heading-white"}>
    {label}
  </h2>
);
