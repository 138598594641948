import {
  Post,
  Footer,
  Map,
  Navigation,
  Topbar,
  TherapistsList,
} from "../components";
import { welcome } from "../shared";

export const HomePage = () => (
  <>
    <Navigation />
    <Topbar />
    <Post isPost={false} label={welcome.label} content={welcome.text} />
    <TherapistsList backgroundColor="grey" />
    <Map />
    <Footer />
  </>
);
