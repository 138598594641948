import { useState, useEffect } from "react";
import { topbarData } from "./topbar.data";
import { Button } from "../../ui";
import "./topbar.css";

export const Topbar = () => {
  const [actuallyService, setActuallyService] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActuallyService((previousValue) =>
        previousValue === topbarData.services.length - 1 ? 0 : previousValue + 1
      );
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="topbar">
      <h2>{topbarData.label}</h2>
      <div className="topbar-carousel">
        <span>{topbarData.services[actuallyService]}</span>
      </div>
      <Button
        path={topbarData.button.path}
        label={topbarData.button.label}
        hasOpacity
      />
    </div>
  );
};
