import "./name-value-pair.css";

interface NameValuePairProps {
  label: string;
  text: string;
  phoneNumber?: string;
}

export const NameValuePair = ({
  label,
  text,
  phoneNumber,
}: NameValuePairProps) => (
  <p className="name-value-pair">
    <span>{label}:</span>
    {phoneNumber ? <a href={`tel:${phoneNumber}`}>{text}</a> : text}
  </p>
);
