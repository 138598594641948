import "./footer.css";
import { copyright, footerPaths } from "./footer.data";

export const Footer = () => (
  <footer>
    <div className="footer-container">
      <ul>
        <ol>
          {footerPaths.leftSide.map((item, index) => (
            <li key={index}>
              <a target="_blank" rel="noreferrer" href={item.path}>
                {item.label}
              </a>
            </li>
          ))}
        </ol>
        <ol>
          {footerPaths.rightSide.map((item, index) => (
            <li key={index}>
              <a target="_blank" rel="noreferrer" href={item.path}>
                {item.label}
              </a>
            </li>
          ))}
        </ol>
      </ul>
      <p>{copyright}</p>
    </div>
  </footer>
);
