import { PostType } from "../interfaces/post.type";

export const rodo: PostType = {
  label: "INFORMACJA DOTYCZĄCA OCHRONY DANYCH OSOBOWYCH",
  text: ` 
    <p style="text-align: justify"><strong>Szanowni Państwo.</p></strong>

    <p style="text-align: justify"><strong>Bezpieczeństwo i poufność Państwa danych jest dla mnie priorytetem. Zapewniam, że zawsze dokładałem i będę dokładać
     wszelkich starań, aby zapewnić Państwu ochronę Waszych danych, poprzez stosowanie odpowiednich zabezpieczeń technicznych i organizacyjnych.</strong></p>

    <p style="text-align: justify"><strong>Niniejsza informacja służy poinformowaniu Państwa o celu, zakresie i kategoriach przetwarzania ich danych osobowych, o czasie przetwarzania danych
     oraz o przysługujących im prawach, zgodnie z obowiązującym od 25 maja 2018r. Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 
     2016r. w sprawie ochrony osób fizycznych, w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 
     95/46/WE (zwany RODO/GDPR).</strong></p>

    <p style="text-align: justify"><strong>Administrator Danych i Inspektor Ochrony Danych.</strong></p>

    <p style="text-align: justify"><strong>Administratorem Państwa Danych jest Gabinet Psychoterapii Artur Kulig z siedzibą w Radlin k/Rybnika, ul. Głożyńska 200E (www.gabinet-psychoterapia.com, 
    www.gabinet-psychoterapia.com.pl).</strong></p>

    <p style="text-align: justify">Jako Administrator Danych, jestem odpowiedzialny za zapewnienie bezpieczeństwa Państwa danych osobowych, sposób ich wykorzystywania zgodnie z obowiązującymi 
    przepisami prawa. Wszelkich dodatkowych informacji możecie Państwo zasięgnąć pod adresem a.kulig@gabinet-psychoterapia.com lub listownie na adres Radlin k/Rybnika, 
    ul. Głożyńska 200E, jako adres siedziby firmy.</p>

    <p style="text-align: justify"><strong>Kategorie zbieranych danych osobowych</strong></p>

    <p style="text-align: justify">Państwa dane osobowe przetwarzane będą w zakresie podanym przez Panią/Pana za pośrednictwem formularza kontaktowego, w celu realizacji usług świadczonych 
    drogą elektroniczną na podstawie art. 6 ust. 1 lit. b RODO (czynności niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą, lub do podjęcia
    działań na żądanie osoby, której dane dotyczą, przed zawarciem umowy).</p>

    <p style="text-align: justify">Podanie danych osobowych na stronie internetowej www.gabinet-psychoterapia.com jest dobrowolne. Podstawą przetwarzania danych jest Państwa zgoda. Mają Państwo 
    wpływ na przeglądarkę internetową i jej ustawienia. Odbiorcami danych osobowych może być Google. Mają Państwo prawo wycofania zgody w dowolnym momencie poprzez 
    zmianę ustawień w przeglądarce. Dane osobowe będą przetwarzane i przechowywane w zależności od okresu używania technologii. Ma Pani/Pan prawo zażądania od administratora
     dostępu do moich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania tych danych, a także prawo wniesienia skargi do organu nadzorczego. 
     Profilowanie używane jest w Google Analytics, Google AdWords, W sytuacji wniesienia sprzeciwu wobec profilowania, prosimy zoptymalizować odpowiednio przeglądarkę.</p>

    <p style="text-align: justify"><strong>Dane osobowe dotyczące osób korzystających z formularza kontaktowego posiadam dzięki wyrażonej zgodzie i przetwarzania ich przez okres 5 lat.</strong></p>

    <p style="text-align: justify"><strong>Prawa osób w zakresie danych osobowych</strong></p>

    <p style="text-align: justify">Posiadają Państwo prawo dostępu do treści swoich danych osobowych, prawo do ich sprostowania, usunięcia, jak i również prawo do ograniczenia ich przetwarzania/ prawo do 
    cofnięcia zgody, prawo do przenoszenia danych, prawo do wniesienia sprzeciwu wobec przetwarzania Państwa danych osobowych. Przysługuje Państwu prawo wniesienia skargi do 
    organu nadzorczego, jeśli Państwa zdaniem, przetwarzanie danych osobowych Pani/Pana – narusza przepisy unijnego rozporządzenia RODO.</p>

    <p style="text-align: justify"><strong>Dodatkowe Informacje</strong></p>

    <p style="text-align: justify">Skargę dotyczącą przetwarzania danych osobowych można złożyć do organu nadzorczego zajmującego się ochroną danych osobowych. W Rzeczpospolitej Polskiej organem 
    nadzorczym jest Prezes Urzędu Ochrony Danych Osobowych.</p>

    <p style="text-align: justify">Przedstawione w niniejszej informacji zasady wynikające z Rozporządzenia o Ochronie Danych Osobowych (RODO) – obowiązują od dnia 25 maja 2018r. do odwołania 
    i realizują obowiązek prawny wynikający z art. 13 – 14 RODO. Zastrzegam sobie prawo do zmian w niniejszej informacji, zawsze w celu poprawy, jakości świadczonych 
    przeze mnie usług oraz w poszanowaniu Państwa praw i prywatności.</p>`,
};
