import "./preloader.css";

export const Preloader = ({ isLoaded }: { isLoaded: boolean }) => (
  <div className={`preloader ${isLoaded ? "site-loaded" : ""}`}>
    <div className="preloader-container">
      <div className="preloader-box" />
      <div className="preloader-box" />
      <div className="preloader-box" />
      <div className="preloader-box" />
    </div>
  </div>
);
