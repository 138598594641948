import { PostType } from "../interfaces/post.type";

export const welcome: PostType = {
  label: "Gabinet Psychoterapii A&M Kulig",
  text: ` Witamy Państwa serdecznie na naszej stronie Gabinetu Psychoterapii A&M Kulig</br><br>

          Jesteśmy <b>certyfikowanymi psychoterapeutami.<br><br>
          Ja jestem również superwizorem SN Psychoterapii i SN Terapii Rodzin Polskiego Towarzystwa Psychiatrycznego.</b><br><br>

          Jeśli przeżywasz trudności, problemy osobiste, małżeńskie czy też rodzinne, chcesz dokonać zmian w swoim życiu, to oferujemy Ci swoją pomoc.<br><br>

          <b>Dodatkowo zachęcam do skorzystania z superwizji.</b><br><br>
          
          Zapraszamy do skorzystania z naszej wiedzy, umiejętności i doświadczenia.<br>
          Możesz przejrzeć naszą stronę i dowiedzieć się więcej.`,
};
