import { useLocation } from "react-router-dom";
import { Footer, Map, Navigation, Therapists } from "../components";
import { TherapetistsData, therapistsData } from "../shared";

export const SingleTherapistsPage = () => {
  const location = useLocation();
  const pathname = location.pathname as TherapistsTypes;

  enum TherapistsTypes {
    Artur = "/terapeuci/artur-kulig",
    Marzena = "/terapeuci/marzena-kulig",
  }

  const therapist: { [key in TherapistsTypes]: TherapetistsData } = {
    [TherapistsTypes.Artur]: therapistsData[0],
    [TherapistsTypes.Marzena]: therapistsData[1],
  };

  return (
    <>
      <Navigation />
      <Therapists data={therapist[pathname]} />
      <Map />
      <Footer />
    </>
  );
};
