import "./input.css";

type AllowedInputTypes = "text" | "number" | "password" | "email";

export interface InputTextProps {
  readonly: boolean;
  placeholder: string;
  type: AllowedInputTypes;
  name: string;
  required: boolean;
}

export const Input = ({
  readonly,
  placeholder,
  type,
  name,
  required,
}: InputTextProps) => {
  return (
    <input
      className="input-text"
      name={name}
      type={type}
      readOnly={readonly}
      placeholder={placeholder}
      required={required}
      minLength={6}
      maxLength={255}
    />
  );
};
