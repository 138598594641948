import "./textarea.css";

export interface TextareaTextProps {
  readonly: boolean;
  placeholder: string;
  name: string;
  required: boolean;
}

export const Textarea = ({
  readonly,
  placeholder,
  name,
  required,
}: TextareaTextProps) => (
  <textarea
    className="textarea"
    name={name}
    readOnly={readonly}
    placeholder={placeholder}
    required={required}
    minLength={25}
    maxLength={3000}
  />
);
