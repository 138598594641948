import "./toggler.css";

export const HamburgerToggler = ({
  isActive,
  showMenu,
}: {
  isActive: boolean;
  showMenu: () => void;
}) => (
  <div
    data-testid="hamburger-toggler"
    onClick={showMenu}
    className={isActive ? "toggler-active" : "toggler"}
  >
    <span></span>
    <span></span>
    <span></span>
  </div>
);
