import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { Heading, Input, NameValuePair, Submit, Textarea } from "../../ui";
import {
  contactData,
  contactFormInputs,
  validationAlertsText,
} from "./contact.data";
import "./contact.css";
import { validatePhone } from "../../shared/validators";
import { useState } from "react";

export const Contact = () => {
  const [notRobot, setNotRobot] = useState<boolean>(false);

  const onChangeRecaptcha = () => setNotRobot((value) => !value);

  const sendMessage = (event: React.FormEvent) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(form);
    const formObject = Object.fromEntries(formData);

    if (!validatePhone(formObject.phone as string))
      return alert(validationAlertsText.wrongPhoneNumber);

    if (!notRobot) return alert(validationAlertsText.reCaptcha);

    emailjs
      .sendForm(
        "service_vuq66nf",
        "template_h564f89",
        form,
        "YNmWCk1s31pSPmjyO"
      )
      .then(
        (result) => {
          form.submit();
        },
        (error) => {
          alert(validationAlertsText.somethingWentWrong);
        }
      );
  };

  return (
    <div className="contact">
      <div className="contact-container">
        <div className="contact-container-box">
          <div className="contact-container-box-content">
            <Heading label={contactData.label} textColor="black" />
            <div className="contact-container-box-content-data">
              {contactData.data.map((item, index) => (
                <NameValuePair
                  label={item.label}
                  text={item.value}
                  phoneNumber={item.phoneNumber}
                  key={index}
                />
              ))}
            </div>
            <form
              onSubmit={sendMessage}
              className="contact-container-box-content-data"
            >
              {contactFormInputs.map((item, index) => (
                <Input
                  name={item.name}
                  placeholder={item.placeholder}
                  readonly={item.readonly}
                  type={item.type}
                  required={item.required}
                  key={index}
                />
              ))}
              <Textarea
                name="message"
                placeholder="Treść wiadomości"
                readonly={false}
                required
              />
              <div className="form-submit-button">
                <Submit hasOpacity={false} label="Wyślij wiadomość" />
              </div>
            </form>
            <ReCAPTCHA
              className="recaptcha"
              sitekey="6LdNLQwnAAAAAIWBbaVKj6kYvZN8LPhOZngtNztu"
              onChange={onChangeRecaptcha}
            />
          </div>
        </div>
        <div className="contact-container-box">
          <iframe
            className="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.167424951017!2d18.43358607634371!3d50.0456948715177!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47114550c7780f5d%3A0xca2184920b1311ef!2zR8WCb8W8ecWEc2thIDIwMEUsIDQ0LTMxMCBSYWRsaW4!5e0!3m2!1sen!2spl!4v1685131663365!5m2!1sen!2spl"
            title="map"
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};
