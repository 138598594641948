import logo from "./logo.svg";
import officeOne from "./office_one.webp";
import artur from "./artur.webp";
import marzena from "./marzena.webp";

interface Images {
  logo: string;
  officeOne: string;
  artur: string;
  marzena: string;
}

export const images: Images = {
  logo,
  officeOne,
  artur,
  marzena,
};
