import { ServiceType } from "../interfaces/therapists.type";

export const aboutArtur: string = `<p><strong><span>Jestem absolwentem Uniwersytetu Śląskiego w Katowicach</span></strong><span>. Dyplom magistra uzyskałem&nbsp;w 1998 roku. Wcześniej ukończyłem Kolegium Nauczycielskie, na kierunku pedagogiki.</span></p>
<p><span>Ukończyłem całościowe, atestowane przez PTP, szkolenie z psychoterapii w Zakładzie Terapii Rodzin Katedry Psychiatrii CM UJ w Krakowie (lata 2003 &ndash; 2008) oraz całościowe szkolenie (Curriculum) z zakresu terapii i hipnozy ericksonowskiej w Polskim Instytucie Ericksonowskim (lata 2000 &ndash; 2007).</span></p>
<p><span>Brałem r&oacute;wnież udział w licznych szkoleniach z zakresu psychoterapii m.in.:</span></p>
<ul type="disc" style="margin-bottom:0cm;">
    <li><span>praca z ciałem A. Lowena: Basic Energetic Principles of Bioenergetic Analysis &ndash; prowadzonych przez Heinera Steckela (lata 2008, 2009,2010),</span></li>
    <li><a href="http://voicedialogueinternational.com/index.htm"><span>praca terapeutyczna Voice Dialogue &ndash; prowadzonych przez Gerharda Fassera (2003 r)</span></a><span>,</span></li>
    <li><span>Terapii Kr&oacute;tkoterminowej Skoncentrowanej na Rozwiązaniu BFST &ndash; prowadzonych przez Luisa Alarcon (2002 r.),</span></li>
    <li><span><a href="http://www.hipnoza.org.pl/"><span>z zakresu hipnozy w psychoterapii &ndash; kurs organizowany przez Towarzystwo Hipnozy Terapeutycznej i Badań nad Hipnozą (2010&ndash;12)</span></a></span><span>,</span></li>
    <li><a href="http://www.balint.pl/"><span>treningu balintowskiego (2010 &ndash; 2015)</span></a><span>,</span></li>
    <li><a href="http://www.isppm.de/"><span>z zakresu psychologii i psychoterapii więzi prenatalnej i okołoporodowej &ndash; według autorskiej metody Barbary Jakel (2012 &ndash; 2014) &ndash; rekomendowane przez International Society of Prenatal and Perinatal Psychology and Medicine</span></a><span>,</span></li>
    <li><a href="http://www.d-gth.de/"><span>z zakresu Hipnointegracyjnej Psychoterapii Głębi metodą Wernera Meinholda (2012 &ndash; 2014), organizowane pod patronatem International Federation of Integrative Depth Psychology in Therapy and Research of Hypnosis.</span></a></li>
</ul>
<p><span>W trosce o jakość swojej pracy oraz osobisty rozw&oacute;j <strong>nadal szkolę się i doskonalę warsztat pracy uczestnicząc w konferencjach, treningach i superwizji</strong>.</span></p>
<p><strong><span>Posiadam Certyfikat</span></strong><span>:</span></p>
<ul type="disc" style="margin-bottom:0cm;">
    <li><span>Psychoterapeuty SN Psychoterapii i SN Terapii Rodzin Polskiego Towarzystwa Psychiatrycznego (nr 492)</span></li>
    <li><span>Superwizora SN Psychoterapii i SN Terapii Rodzin Polskiego Towarzystwa Psychiatrycznego (nr 160)</span></li>
    <li><span>European Certificate of Psychotherapy (ECP) wydawany przez European Association for Psychotherapy (EAP)</span></li>
    <li><span>Certyfikat psychoterapeuty Więzi Prenatalnej i Okołoporodowe rekomendowany przez International Society of Prenatal and Perinatal Psychology and Medicine (ISPPM)</span></li>
    <li><span>Międzynarodowy Certyfikat Hipnoterapeuty wydany przez INTERNATIONALE GESELLSCHAFT F&Uuml;R THERAPEUTISCHE HYPNOSE UND HYPNOSEFORSCHUNG (I-GTH) &ndash; Międzynarodowe Towarzystwo Hipnozy Terapeutycznej i Badań nad Hipnozą&nbsp;(I-GTH)</span></li>
    <li><span>Certyfikat Lidera Grup Balinta wydany przez Polskie Stowarzyszenie Balintowskie.</span></li>
</ul>
<p><strong><span>Doświadczenie zawodowe</span></strong><span>&nbsp;zdobywałem w wielu r&oacute;żnych instytucjach i ośrodkach terapeutycznych. Pracowałem w poradniach psychologiczno-pedagogicznych, ośrodkach pomocy społecznej, świetlicach socjoterapeutycznych, poradniach zdrowia psychicznego i oddziałach dziennych, stosujących psychoterapię.<br>&nbsp;Obecnie pracuję w kilku poradniach zdrowia psychicznego oraz oddziałach dziennych na terenie wojew&oacute;dztwa śląskiego, jako psychoterapeuta i superwizor.<br>&nbsp;Przez trzy lata byłem kierownikiem i psychoterapeutą na oddziale dziennym leczenia nerwic w Rybniku &ndash; psychoterapia w ramach NFZ.<br>&nbsp;Wsp&oacute;łpracuję z Wrocławskim Instytutem Psychoterapii oraz ze szkołami psychoterapii akredytowanymi przez SNP PTP m.in. ze Śląską Szkołą Psychoterapii.<br> <strong>Od 2001 roku</strong> prowadzę gabinet psychoterapii.</span></p>
<p><strong><span>Doświadczenie kliniczne</span></strong><span>&nbsp;i terapeutyczne zdobywałem m.in. w:</span></p>
<ul type="disc" style="margin-bottom:0cm;">
    <li><span>Ośrodku Leczenia Nerwic i Zaburzeń Jedzenia &bdquo;Dąbr&oacute;wka&rdquo; w Gliwicach, prowadząc psychoterapię grupową oraz terapię rodzin (lata 2001 &ndash; 2003),</span></li>
    <li><span>Centrum Zdrowia Psychicznego &bdquo;Feniks&rdquo; w Sosnowcu, prowadząc psychoterapię grupową na Dziennym Oddziale Leczenia Nerwic i na Dziennym Oddziale Psychiatrycznym w Zabrzu (lata 2003 &ndash; 2010),</span></li>
    <li><span>odbywając staże kliniczne w Zakładzie Terapii Rodzin w Krakowie oraz Ośrodku Leczenia Nerwic w Gliwicach (2008),</span></li>
    <li><span>filii Polskiego Instytutu Ericksonowskigo we Wrocławiu (lata 2008 &ndash; 2010),</span></li>
    <li><span>prowadząc prywatną praktykę psychoterapii (od 2001 roku).</span></li>
</ul>
<p><strong><span>Należę do</span></strong><span>&nbsp;Polskiego Towarzystwa Psychiatrycznego, Polskiego Towarzystwa Psychologicznego, European Association for Psychotherapy, Towarzystwa Hipnozy Terapeutycznej i Badań nad Hipnozą oraz Polskiego Stowarzyszenia Balintowskiego.</span></p>
<p><span>Przez dwie kadencje byłem członkiem Zarządu Towarzystwa Hipnozy Terapeutycznej i Badań nad Hipnozą.</span></p>
<p><span>Należę r&oacute;wnież do Śląskiego Stowarzyszenia Psychoterapeut&oacute;w a od 2010 roku jestem członkiem Zarządu tego Stowarzyszenia. Od 2023 roku jest członkiem zarządu Sekcji Naukowej Terapii Rodzin Polskiego Towarzystwa Psychiatrycznego.</span></p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>&nbsp;</p>`;

export const servicesArtur: ServiceType[] = [
  {
    title: "Psychoterapia indywidualna",
    content: `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;'><strong><span style="">Psychoterapia indywidualna</span></strong><span style="">&nbsp;</span>skierowana jest do os&oacute;b dorosłych cierpiącym na r&oacute;żne dolegliwości natury psychicznej i emocjonalnej. Może być r&oacute;wnież pomocna w sytuacji doświadczania kryzysu. Zwykle poprzedzą ją tzw. spotkania konsultacyjne, na kt&oacute;rych omawiane są okoliczności i powody zgłoszenia oraz oczekiwania związane z terapią, omawiany jest r&oacute;wnież kontrakt, czyli zasady wzajemnej wsp&oacute;łpracy.<br>&nbsp;Terapia indywidualna najczęściej odbywa się raz w tygodniu.<br>&nbsp;<br><strong><em><span style="">Spotkanie konsultacyjne, psychoterapia indywidualna trwa 45 minut, kosztuje 200 zł.</span></em></strong><br>&nbsp;</p>`,
  },
  {
    title: "Psychoterapia par i małżeństw",
    content: `<p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;'><strong><span style=''>Psychoterapia par i małżeństw&nbsp;</span></strong><strong><span style=''>jest</span></strong> metodą wprowadzania zmian w sytuacji kiedy małżeństwo, para, przeżywa kryzys, trudności i problemy, kiedy znajdzie się w impasie. Są to często sytuacje związane z wejściem w nowy etap życia rodzinnego np. narodziny dziecka, opuszczenie domu rodzinnego, przejście na emeryturę, śmierć bliskiej osoby. Mogą one być r&oacute;wnież związane z sytuacjami kryzysowymi np. chorobą w rodzinie, zmianą miejsca zamieszkania, emigracją zarobkową, zdradą małżeńską itp. Podobnie jak w terapii indywidualnej, do osiągania cel&oacute;w terapii, potrzebne jest zaangażowanie oraz wsp&oacute;łpraca wszystkich os&oacute;b uczestniczących w terapii. Możliwa jest r&oacute;wnież taka sytuacja, kiedy jakaś osoba nie jest całkowicie przekonana o skuteczności terapii. To nie wyklucza możliwości podjęcia wsp&oacute;lnej pracy terapeutycznej. Jednak pozytywne zmiany w relacji są możliwe jedynie wtedy, gdy wszystkie osoby uczestniczące w terapii zaangażują się w tą zmianę.&nbsp;<br><strong><span style=''>Psychoterapia nie może nikogo zmienić wbrew jego woli</span></strong>.<br>&nbsp;<br><strong><em><span style="">Konsultacja i terapia małżeńska/pary trwa 50 minut, kosztuje 300 zł. Terapia ta odbywa się co dwa, trzy tygodnie.</span></em></strong></p>`,
  },
  {
    title: "Analiza biograficzna w hipnozie",
    content: `<p><strong><span style="">Analiza biograficzna w hipnozie</span></strong><span style="">&nbsp;</span>(Hipnointegracyjna Psychoterapia Głębi (H.I.T.T.&reg;), to autorska metoda Wernera J. Meinholda (ur. 1 kwietnia 1944, zm. 17 października 2019).</p>
            <p><strong>Werner J. Meinhold był znanym niemieckim psychoterapeutą</strong>, autorem wielu opracowań, książek i publikacji w czasopismach naukowych i popularnonaukowych. Jego książka &bdquo;Wielki Podręcznik Hipnozy&rdquo; stanowi uwieńczenie jego długoletnich studi&oacute;w i praktyki w zakresie terapeutycznego zastosowania hipnozy, ale także głębokim studium zjawiska hipnozy występującego w życiu społecznym, politycznym i gospodarczym. W Niemczech ukazało się już 10 wydanie tego dzieła, kt&oacute;re było tłumaczone na wiele język&oacute;w. Był r&oacute;wnież autorem podręcznika dla psychoterapeut&oacute;w i pacjent&oacute;w &bdquo;Psychoterapia w hipnozie&rdquo; (przetłumaczona na język polski przez dr Kazimierza Pietruszewskiego, ukazała się drukiem w 1997 roku), w kt&oacute;rym w prosty i przejrzysty spos&oacute;b tłumaczy wiele zagadnień dotyczących psychoterapii, procesu leczenia i hipnozy, ale przede wszystkim przedstawia swoją metodę psychoanalitycznej terapii w hipnozie. Werner Meinhold prowadził wykłady i seminaria na całym świecie. Wsp&oacute;łpracował z uniwersytetem w Ekwadorze ucząc swojej metody.&nbsp;Był wieloletnim Prezydentem International Federation of Integrative Depth Psychology in Therapy and Research of Hypnosis (<strong>INFIDEPTH</strong>).&nbsp;Został uhonorowany tytułem Professional of the Year 2005 przyznawanym przez Międzynarodowe Centrum Biograficzne przy uniwersytecie w Cambridge, kt&oacute;re powstało dla podkreślania i rozpowszechniania osiągnięć wybitnych postaci z całego świata. <strong>Werner Meinhold stworzył metodę psychoterapii w hipnozie</strong>, kt&oacute;ra za pomocą regresji w czasie umożliwia dotarcie do głębokich warstw psychiki, m&oacute;zgu (pamięci utajonej) i pracę terapeutyczną w tych obszarach umysłu.</p>
            <p><strong>Jego historia z Polską wiąże się z osobą</strong> wybitnego psychiatry i psychoterapeuty, superwizora psychoterapii PTP , kierownika Zakładu Psychiatrii Środowiskowej Katedry Psychiatrii CMUJ w Krakowie <strong>profesora Andrzej Cechnickiego, wsp&oacute;łinicjatora i wsp&oacute;łzałożyciela GTH Polska (THTiBnH)</strong>. Profesor Andrzej Cechnicki wiele lat temu po raz pierwszy zaprosił Wernera J. Meinholda do Krakowa a potem przez kilka lat wsp&oacute;łorganizował spotkania, seminaria, warsztaty z jego udziałem.</p>
            <p><strong>Werner w swojej metodzie odwoływał się do kilku podstawowych założeń.</strong></p>
            <p>Między innymi <strong>zakładał</strong>, podobnie jak psychoanalitycy, <strong>że umysł człowieka posiada część świadomą i nieświadomą</strong> oraz że czynniki i procesy nieświadome mają decydujący wpływ na życie i funkcjonowanie człowieka i relacje z innymi ludźmi, <strong>ale r&oacute;wnież, że można się uwolnić od ich negatywnego wpływu na teraźniejsze życie</strong>. Uważał też, że <strong>stan hipnozy jest</strong>, obok stanu czuwania i snu, <strong>naturalnym stanem umysłu człowieka</strong> pojawiającym się spontanicznie w r&oacute;żnych momentach w ciągu dnia.</p>
            <p><strong>Zauważył, że dziecko do ok. 6 roku życia przebywa w znacznej mierze w stanie hipnozy</strong>. I w związku z tym wczesne traumy, deficyty i konflikty, kt&oacute;re przeżywa dziecko mogą być przepracowane w dorosłym życiu w trakcie terapii jedynie przy wykorzystaniu hipnozy, czyli powrotem do tego &bdquo;wczesnego&rdquo; stanu umysłu człowieka. Wtedy właśnie możliwe jest dotarcie do tych pierwotnych źr&oacute;deł cierpienia i choroby.</p>
            <p><strong>Celem psychoterapii</strong> w ujęciu Meinholda nie jest jedynie usunięcie objaw&oacute;w, ponieważ traktuje on objawy choroby, podobnie jak wielu terapeut&oacute;w, jako wyraz i ekspresję głębszych problem&oacute;w, kt&oacute;re należy rozwiązać. <strong>Cele psychoterapii określa raczej jako integrację sił cielesnych, psychicznych i duchowych człowieka i rozw&oacute;j osobowości w kierunku wolności</strong>. A rozw&oacute;j rozumiany był przez niego jako integrowanie nieświadomych element&oacute;w osobowości (usunięcie barier rozwojowych powstałych w poszczeg&oacute;lnych etapach życia).</p>
            <p><strong>Dużą wagę w terapii Werner przywiązywał</strong> r&oacute;wnież do poszerzania pola samoświadomości pacjenta.<br>&nbsp;W H.I.T.T&reg; istotne jest także integrowanie odszczepionych (oderwanych) element&oacute;w doświadczeń biograficznych oraz reparacja (naprawianie) traumatycznych doświadczeń a także ich odreagowanie (katharsis).</p>
            <p><strong>W swojej metodzie Werner odwoływał się m.in. do teorii analitycznej</strong>, szczeg&oacute;lnie w zakresie faz rozwoju człowieka, jego dojrzewania. Jednak nieco inaczej niż psychoanalitycy widzi on fazy rozwojowe i ich znaczenie. Ponad to uzupełnia je o fazy nie opisywane powszechnie przez psychoanalizę tzn. o okres życia płodowego oraz fazę porodu, kt&oacute;ra stanowi swoiste przejście z życia płodowego do realnego. Te wczesne fazy życia mają często, zdaniem Meinholda, kluczowe znaczenie dla prawidłowego rozwoju człowieka. Wszelkie deficyty, urazy powstałe w tym wczesnym okresie rozwoju człowieka, zwłaszcza dotyczące bezwarunkowej miłości i akceptacji dziecka przez matkę, odciskają głębokie piętno w jego rozwoju oraz są przyczynami poważnych zaburzeń psychicznych (np. wsp&oacute;łczesne badania kliniczne dotyczące przyczyn autyzmu u dzieci, potwierdzają doświadczenia z jego praktyki terapeutycznej).</p>
            <p><strong>Jednym z podstawowych warunk&oacute;w stosowania tej metody jest gotowość pacjenta do samopoznania i rozwoju oraz aktywnego udziału w terapii.</strong></p>
            <p><strong><em><span style="">Cena za sesję hipnoterapii wynosi 250 zł</span></em></strong><em><span style="">. <strong>Czas sesji to 50 minut.</strong></span></em></p>`,
  },
  {
    title: "Superwizja",
    content: `<p><strong><span style="">Superwizja&nbsp;</span></strong>to ważne narzędzie zwiększania efektywności pracy terapeutycznej i rozwoju zawodowego psychoterapeut&oacute;w (formacji zawodowej). Superwizja stanowi cenną i uznaną formę koleżeńskiej wsp&oacute;łpracy i pomocy i jest niezbędnym elementem prowadzenia psychoterapii, niezależnie od stopnia zaawansowania w tym zawodzie.<br>&nbsp;Stanowi r&oacute;wnież, obok szkolenia dydaktycznego i tzw. &bdquo;pracy własnej&rdquo;, podstawowy element kształcenia przyszłych terapeut&oacute;w. Celem superwizji jest pomoc terapeucie w przyglądaniu się sobie oraz własnemu doświadczeniu w pracy z pacjentem oraz ewentualnym przeszkodom i trudnościom leżącym zar&oacute;wno po stronie samego terapeuty jak i pacjenta.</p>
            <p>Superwizja daje r&oacute;wnież m.in. możliwość:</p>
            <ul type="disc" style="margin-bottom:0cm;">
                <li>rozumienia procesu terapeutycznego i jego poszczeg&oacute;lnych etap&oacute;w i element&oacute;w,</li>
                <li>analizy przeniesień i przeciwprzeniesień w procesie terapii,</li>
                <li>refleksji nad zastosowaniem wiedzy i umiejętności w prowadzonej psychoterapii,</li>
                <li>poszukiwania i rozwijania własnego stylu, rozwoju kompetencji i zasob&oacute;w terapeutycznych,</li>
                <li>wsparcia w trudnych momentach terapii oraz regeneracji sił terapeuty,</li>
                <li>refleksji związanej z przestrzeganiem zasad etyki zawodowej.</li>
            </ul>
            <p style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;'><em><span style="">Częstotliwość spotkań superwizyjnych ustalamy indywidualnie, w zależności od potrzeb i zakładanych cel&oacute;w. <strong>Cena za godzinę superwizji wynosi 300 zł.</strong> Może odbywać się r&oacute;wnież w formie online.&nbsp;</span></em></p>`,
  },
];

export const certificatesArtur = [
  "Psychoterapeuty SN Psychoterapii i SN Terapii Rodzin Polskiego Towarzystwa Psychiatrycznego (nr 492)",
  "Superwizora SN Psychoterapii i SN Terapii Rodzin Polskiego Towarzystwa Psychiatrycznego (nr 160)",
  "European Certificate of Psychotherapy (ECP) wydawany przez European Association for Psychotherapy (EAP)",
  "Certyfikat psychoterapeuty Więzi Prenatalnej i Okołoporodowe rekomendowany przez International Society of Prenatal and Perinatal Psychology and Medicine (ISPPM)",
  "Międzynarodowy Certyfikat Hipnoterapeuty wydany przez INTERNATIONALE GESELLSCHAFT FÜR THERAPEUTISCHE HYPNOSE UND HYPNOSEFORSCHUNG (I-GTH) – Międzynarodowe Towarzystwo Hipnozy Terapeutycznej i Badań nad Hipnozą (I-GTH)",
  "Certyfikat Lidera Grup Balinta wydany przez Polskie Stowarzyszenie Balintowskie.",
];
