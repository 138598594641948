import "./spoiler.css";

interface SpoilerType {
  label: string;
  content: string;
}

export const Spoiler = ({ label, content }: SpoilerType) => (
  <details>
    <summary>{label}</summary>
    <p dangerouslySetInnerHTML={{ __html: content }} />
  </details>
);
