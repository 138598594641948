import { PostType } from "../interfaces/post.type";

export const privacy: PostType = {
  label: "Polityka prywatności",
  text: ` 
    <p style="text-align: justify"><b>Przez używanie strony wyrażasz zgodę na używanie ciasteczek zgodnie z Polityką Ciasteczek. Jeżeli nie zgadzasz
     się na używanie ciasteczek, powinieneś zmienić ustawienia swojej przeglądarki w odpowiedni sposób lub zrezygnować
     z używania strony.</b></p>

    <p style="text-align: justify"><b>Co to są ciasteczka?</b></p>
    <p style="text-align: justify">Ciasteczka (ang. cookies) to niewielkie pliki, zapisywane i przechowywane na twoim komputerze, tablecie lub 
    smartphonie podczas gdy odwiedzasz różne strony w internecie. Ciasteczko zazwyczaj zawiera nazwę strony internetowej,
    z której pochodzi, „długość życia” ciasteczka (to znaczy czas jego istnienia), oraz przypadkowo wygenerowany unikalny
    numer służący do identyfikacji przeglądarki, z jakiej następuje połączenie ze stroną internetową.</p>

    <p style="text-align: justify"><b>Do czego są używane ciasteczka?</b></p>
    <ul style="text-align: justify">
    <li>Pomagają by strona działała szybciej i była łatwiejsza w użyciu.</li>
    <li>Pomagają lepiej dopasować treści i reklamy do Twoich oczekiwań i zainteresowań.</li>
    <li>Służą do zbierania anonimowych statystyk, które pozwalają zrozumieć jak używana jest strona i pomagają w poprawianiu
        ich funkcjonalności i zawartości.</li>
    </ul>

    <p style="text-align: justify"><b>Rodzaje plików Cookies wykorzystywane na stronie.</b></p>
    <table style="width: 100%; border-collapse: collapse;">
        <tr style="background-color: #f2f2f2; font-size: 12px">
            <th style="padding: 8px; text-align: left; border-right: 1px solid #ddd; border-left: 1px solid #ddd;">RODZAJ PLIKU COOKIE</th>
            <th style="padding: 8px; text-align: left; border-right: 1px solid #ddd;">NAZWA PLIKU COOKIE</th>
            <th style="padding: 8px; text-align: left; border-right: 1px solid #ddd;">CEL WYKORZYSTYWANIA PLIKU COOKIE</th>
            <th style="padding: 8px; text-align: left; border-right: 1px solid #ddd;">CZY USUNIĘCIE PLIKU UNIEMOŻLIWI KORZYSTANIE Z WITRYNY?</th>
        </tr>
        <tr style="font-size: 12px">
            <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; border-left: 1px solid #ddd;">sesyjne</td>
            <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">qtrans_cookie_test, WP-cookie-info, icl_current_language (WordPress)</td>
            <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">Przechowywanie identyfikatora sesji użytkownika i aktualnej wersji językowej.</td>
            <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">Nie</td>
        </tr>
        <tr style="font-size: 12px">
            <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd; border-left: 1px solid #ddd;">podmiotów zewnętrznych</td>
            <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">PREF, testcookie, khcookie, NID, SNID, _utma, _utmb, _utmc, _utmz</td>
            <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">Google Maps, Google Analytics, Reklamy Google – Zbieranie anonimowych statystyk. Więcej na: <a style="color: #000"}href="http://www.google.pl/intl/pl/policies/technologies/types/" style="color: blue; text-decoration: none;">http://www.google.pl/intl/pl/policies/technologies/types/</a>.</td>
            <td style="padding: 8px; text-align: left; border-bottom: 1px solid #ddd; border-right: 1px solid #ddd;">Nie</td>
        </tr>
    </table>

    <p style="text-align: justify"><b>Konfigurowanie i usuwanie plików „cookies”</b></p>
    <p style="text-align: justify">Przeglądarka internetowa domyślnie dopuszcza umieszczanie plików „cookies” na urządzeniu końcowym. Ustawienia te mogą zostać zmienione
     w taki sposób, aby blokować automatyczną obsługę plików „cookies” w ustawieniach przeglądarki internetowej lub informować o ich 
     każdorazowym przesłaniu na urządzenie użytkownika. Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są
    w ustawieniach oprogramowania (przeglądarki internetowej).</p>`,
};
