import { Footer, Map, Navigation, Post } from "../components";

export const NotFoundPage = () => {
  return (
    <>
      <Navigation />
      <Post
        label="404"
        content="Strona o podanym adresie nie istnieje..."
        isPost
      />
      <Map />
      <Footer />
    </>
  );
};
