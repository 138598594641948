import { Routes, Route } from "react-router-dom";
import {
  ContactPage,
  HomePage,
  NotFoundPage,
  SinglePage,
  SingleTherapistsPage,
  TherapistsPage,
} from "./pages";

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/terapeuci" element={<TherapistsPage />} />
      <Route path="/terapeuci/artur-kulig" element={<SingleTherapistsPage />} />
      <Route
        path="/terapeuci/marzena-kulig"
        element={<SingleTherapistsPage />}
      />
      <Route path="/gabinet" element={<SinglePage />} />
      <Route path="/psychoterapia" element={<SinglePage />} />
      <Route path="/polityka-prywatnosci" element={<SinglePage />} />
      <Route path="/rodo" element={<SinglePage />} />
      <Route path="/kontakt" element={<ContactPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
